import { graphql } from "gatsby";
import { imageManager, stdFluidImage } from "../../components/Image";
import SupportDoc from "../../layouts/WithSideBarPage/_supportDocPage";

const ExcelAutomation = ({ data }) => (
  <SupportDoc
    frontmatter={data.markdownRemark.frontmatter}
    htmlAst={data.markdownRemark.htmlAst}
    allPagesComments={data.allPagesComments}
    imageRenderer={imageManager(data.pageImages)}
  />
);

export default ExcelAutomation;

export const query = graphql`
  query getExcelAutomation(
    $path: String!
    $siteLanguage: String!
    $commentsPage: String!
  ) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      htmlAst
      frontmatter {
        seo {
          description
          link
          title
        }
        path
        bannerTitle
        bannerSubTitle
        href
        featureToolkit
      }
    }
    allPagesComments(
      sort: { fields: id__normalized, order: ASC }
      filter: { pageKey: { eq: $commentsPage } }
    ) {
      nodes {
        id__normalized
        author
        date(formatString: "MMMM D, YYYY [at] HH:mm", locale: $siteLanguage)
        content
        parentId
        avatarUrl
      }
    }
    pageImages:allFile(
      filter: {
        relativePath: {
          glob: "images/**"
        },
        extension: {
          in: ["png", "jpg", "jpeg"]
        }
      }
    )
    {
      nodes {
        ...stdFluidImage
      }
    }
  }
`;
